Office.onReady((info) => {
	if (info.host === Office.HostType.Word) {
		//check if it is the first time, if so, show HOW TO guide
		var firstTime = localStorage.firstTime;
		if(firstTime == 1){
			document.getElementById('guide').className = "hide";
			document.getElementById('index').className = "show";
		}
		else{
			console.log("first time");
			showSlides(1);
			document.getElementById('guide').className = "show";
			document.getElementById('index').className = "hide";
		}

		//clear and set deafult text for test
		//clearBodyAndInsertText();	
		
		console.log("LiboBerry loaded successfully.");
	}

	//clear all tabs content
	try{
		// selectedPrjRes.style.display = "none";
	}
	catch{

	}	
});

function clearBodyAndInsertText() {
	Word.run(function(context) {				
		// Clear the content of the body
		var body = context.document.body;			
		body.clear();

		// Get the current selection
		var selection = context.document.getSelection();

		// Insert text into the selection
		selection.insertText("A Two-Layer Dimension Reduction and Two-Tier Classification Model for Anomaly-Based Intrusion Detection in IoT Backbone Networks\nan AI-powered secure architecture for edge layer of Internet of things\nTwo-Layer Dimension Reduction and Two-Tier Classification Model for Anomaly-Based Intrusion Detection in IoT Backbone Networks Two-Layer Dimension Reduction and Two-Tier Classification Model for Anomaly-Based Intrusion Detection in IoT Backbone Networks", "Replace");

		// Run the queued commands and return a promise to indicate task completion
		return context.sync().then(function() {
			//console.log("Text inserted successfully.");
			
		});
	})
	.catch(function(error) {
		console.log("Error: " + error.message);
	});	
}

document.getElementById('btn-check-current-plagiarism').onclick = checkCurrentPlagiarism;
document.getElementById('btn-check-current-citation').onclick = checkCurrentCitaion;
document.getElementById('select-project-list-result').onclick = btnProjectList;	
document.getElementById('btn-login').onclick = login;	